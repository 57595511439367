<template>
<Layout>
  <form @submit.prevent="submit">
    <div class="section-content max-w-md mx-auto">
      <div class="form">
        <div class="form-heading text-center mb-6">
          <h5>Faça login com seu protocolo</h5>
          <p class="text-sm">
            Caso não tenha protocolo,
            <router-link
              class="font-medium"
              :to="{ name: 'PetitionRegister' }"
            >
              clique aqui para nova petição
            </router-link>
          </p>
        </div>

        <div class="body">
          <div
            :class="{ 'group-horizontal': true, groupError: $v.protocolo.$error }"
          >
            <label class="label"> Protocolo </label>

            <input
              type="text"
              placeholder="Informe seu protocolo"
              v-model.trim="$v.protocolo.$model"
            />
          </div>

          <div
            class="text-red-700 text-sm py-2 text-right"
            v-if="$v.protocolo.$error && !$v.protocolo.required"
          >
            Protocolos deve ser preenchido.
          </div>
          <div
            class="text-red-700 text-sm py-2 text-right"
            v-if="$v.protocolo.$error && !$v.protocolo.minLength"
          >
            Protocolos tem 15 caracteres
          </div>
          <div
            :class="{
                'group-horizontal': true,
                groupError: $v.password.$error,
              }"
          >
            <label class="label"> Senha </label>

            <input
              type="password"
              placeholder="Informe sua senha"
              v-model.trim="$v.password.$model"
            />
          </div>

          <div
            class="text-red-700 text-sm py-2 text-right"
            v-if="$v.password.$error && !$v.password.required"
          >
            Senha deve ser preenchida.
          </div>
        </div>
      </div>

      <div class="form-footer">
        <Button
          type="submit"
          color="white"
          background="lime-600"
          :group="true"
        >
          Acessar
        </Button>
      </div>
    </div>
  </form>
</Layout>
</template>

<script>
import Layout from "../../components/layout/petition/Layout.vue";
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Button from "@/components/elements/Button.vue";
import api from "../../api";

export default {
  name: "PetitionLogin",
  components: {
    Layout,
    Button,
  },
  data() {
    return {
      protocolo: "",
      password: "",
    };
  },
  validations: {
    protocolo: {
      required,
      minLength: minLength(15)
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    toRecovery() {
      this.$router.push({ name: "SubscriptionRecovery" });
    },
    async login(loginData) {
      let response = await api.post('peticao/consulta', loginData)
      console.log(response.data)
      return response.data || {}
    },
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.changeLoading(true);

        let loginData = {
          protocolo: this.protocolo,
          senha: this.password,
        };

        const peticao = await this.login(loginData);

        if (peticao.id) {
          this.changeLoading(false);

          this.sendNotification({
            type: "success",
            message: 'Protocolo localizado',
          });

          this.$router.push({ name: "PetitionSituation", params: {peticao} });
        } else {
          this.changeLoading(false);

          this.sendNotification({
            type: "error",
            message: 'Protocolo não localizado',
          });
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
